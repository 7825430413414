/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, VideoIframe, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aids/hearing-aids-keep-brain-healthy/'], ['en', 'https://www.hear.com/resources/hearing-aids/hearing-aids-keep-brain-healthy/'], ['en-US', 'https://www.hear.com/resources/hearing-aids/hearing-aids-keep-brain-healthy/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aids/hearing-aids-keep-brain-healthy/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "hearing-aids-keep-your-brain-healthy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-keep-your-brain-healthy",
    "aria-label": "hearing aids keep your brain healthy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aids Keep Your Brain Healthy"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A common misunderstanding about hearing loss is that it only affects the ears. But our ears and brain work together to understand speech and process sounds. Hearing loss causes your brain to work harder. For instance, it can be harder to follow conversations and understand every word. You probably have to listen harder, read lips or use additional context clues to get the message. This extra stress on your brain can put you at an increased risk of cognitive decline and dementia."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Research from Johns Hopkins confirms the negative effects hearing loss has on the brain.1 In a study that followed 639 adults for almost 12 years, lead researcher Frank Lin, M.D., Ph.D., discovered that mild hearing loss doubled the risk of developing dementia. That same risk tripled for people with moderate hearing loss. And people with a severe hearing loss were 5X more likely to develop dementia."), "\n", React.createElement(VideoIframe, {
    title: "Better Hearing and Speech Month | Johns Hopkins Cochlear Implant Center",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/lWFZxsOWOhE?feature=oembed"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearing-aids-improve-brain-function",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearing-aids-improve-brain-function",
    "aria-label": "how hearing aids improve brain function permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hearing aids improve brain function"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A study published in the American Journal of Audiology discovered that hearing aids improve brain function in people with hearing loss. Researchers examined people in their 50s and 60s with the most common type of hearing loss (bilateral sensorineural hearing loss) that had never worn hearing aids. The study tested the participants’ working memory, selective attention, and brain processing speed. They wore hearing aids for six months for approximately eight hours a day. By the end of the study, the researchers collected the following data:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Working memory improved by 14%"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Selective attention improved by 20%"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Brain processing speed increased by 14%"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your brain works better when it can hear clearly. Hearing aids allow for effortless hearing. As a result, the brain spends its time on more important tasks. With hearing aids, you’ll hear better, remember more, and process information faster!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-reduce-the-risk-of-developing-mental-health-issues",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-reduce-the-risk-of-developing-mental-health-issues",
    "aria-label": "hearing aids reduce the risk of developing mental health issues permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids reduce the risk of developing mental health issues"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A study in the Journal of American Geriatrics Society found that hearing aids significantly lessen the chance of developing these mental issues. The results of the study showed that wearing hearing aids in the first three years of a hearing loss diagnosis reduced the risk of dementia, depression, or fall-related injuries."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wearing a hearing aid reduced the risk of the following conditions:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Dementia (including Alzheimer’s) by 18%"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Depression or anxiety by 11%"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fall-related injuries by 13%"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-keep-your-brain-fit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-keep-your-brain-fit",
    "aria-label": "hearing aids keep your brain fit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids keep your brain fit"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To sum up, hearing aids allow your brain to hear and understand the world around you. And there’s plenty of evidence to suggest that hearing aids can decrease the cognitive risks associated with hearing loss. If you have any questions about hearing aids and how they can keep your brain healthy, please contact us. And make sure to sign up for a 45-day trial if you haven’t done so yet."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a 45-day trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sources:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4075051/\nhttps://pubs.asha.org/doi/10.1044/2016_AJA-15-0067\nhttps://onlinelibrary.wiley.com/doi/10.1111/jgs.16109"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
